import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AnyField } from 'types/fields';
import type { AnyPage } from 'types/page';

type Message = {
  message: string;
  missingField?: string;
  activeField?: AnyField | AnyPage;
  activeStep?: number;
  currentTool?: string;
};

type UnsavedFieldReducer = {
  errors: Message[];
  // eslint-disable-next-line
  initialFieldState: AnyField | {};
  fieldStep: number;
  fieldSectionIndex: number;
  isDirty: boolean;
};

const initialState: UnsavedFieldReducer = {
  errors: [],
  initialFieldState: {},
  fieldStep: null,
  fieldSectionIndex: null,
  isDirty: false,
};

export const unsavedFieldSlice = createSlice({
  name: 'unsavedFieldSlice',
  initialState,
  reducers: {
    clearUnsavedFieldStore: (state) => {
      return {
        ...state,
        errors: [],
        initialFieldState: {},
        fieldStep: null,
        fieldSectionIndex: null,
        isDirty: false,
      };
    },
    setInitialFieldState: (
      state,
      action: PayloadAction<{
        field: AnyField;
        fieldStep: number;
        fieldSectionIndex: number;
      }>,
    ) => {
      const { field, fieldStep, fieldSectionIndex } = action.payload;
      return {
        ...state,
        initialFieldState: field,
        fieldStep,
        fieldSectionIndex,
      };
    },
    clearInitialFieldState: (state) => {
      return {
        ...state,
        initialFieldState: {},
        fieldStep: null,
        fieldSectionIndex: null,
      };
    },
    setUnsavedFieldErrors: (state, action: PayloadAction<Message[]>) => {
      return {
        ...state,
        errors: action.payload,
      };
    },
    changeIsDirty: (state, action: PayloadAction<boolean>) => {
      return { ...state, isDirty: action.payload };
    },
    clearUnsavedFieldError: (state) => {
      return { ...state, errors: [] };
    },
  },
});

export const {
  changeIsDirty,
  clearInitialFieldState,
  clearUnsavedFieldError,
  clearUnsavedFieldStore,
  setInitialFieldState,
  setUnsavedFieldErrors,
} = unsavedFieldSlice.actions;
export default unsavedFieldSlice.reducer;
