import React from "react";
import { Spinner } from "@chakra-ui/react";
import styled from "@emotion/styled";

function Loader() {
  return (
    <StyledSpinner data-testid="loading">
      <Spinner size="xl" />
    </StyledSpinner>
  );
}

export default Loader;

const StyledSpinner = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(5px);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
`;
