/* istanbul ignore file */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, ButtonGroup, Flex, HStack, Text } from '@chakra-ui/react';
import { FormType } from '@common/entities';
import { EntrataSession } from 'auth/EntrataSession';
import { VIEWER_URL } from 'config/URL';
import { RootState } from 'state/store';

import { PencilIcon } from 'components/custom-icons';

interface PreviewResponsiveWrapperProps {
  formType: FormType;
}

export function PreviewResponsiveWrapper({
  formType,
}: PreviewResponsiveWrapperProps) {
  const [iframeSize, setIframeSize] = useState('100%');
  const [activeButton, setActiveButton] = useState('desktop');

  const selectedApplication = useSelector(
    (state: RootState) => state.application.selectedApplication,
  );

  const { applicationId, versionId } = useParams();
  const navigate = useNavigate();

  const handleSizeChange = (size: string, button: string) => {
    setIframeSize(size);
    setActiveButton(button);
  };

  const handleEditClick = () => {
    if (formType === FormType.Route) {
      navigate(`/routes/route/${applicationId}/${versionId}`);
    } else {
      navigate(`/application/form-builder/${applicationId}/${versionId}`);
    }
  };

  return (
    <Flex
      direction="column"
      bg="gray.highlighthalf"
      flexBasis="100%"
      height="calc(100% - 100px)"
    >
      <Flex
        direction="column"
        flex="1"
        p={4}
        bg="gray.highlighthalf"
        maxW="1800px"
        w="100%"
        m="auto"
      >
        <Flex justifyContent="space-between" mb={6} p={4}>
          <Box>
            <Text fontSize="2xl">{selectedApplication?.name}</Text>
          </Box>
          <HStack spacing={4}>
            <Button
              leftIcon={<PencilIcon boxSize={5} />}
              onClick={handleEditClick}
            >
              Edit
            </Button>
            <Button variant="action" onClick={() => navigate(`/`)}>
              Exit
            </Button>
          </HStack>
        </Flex>
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          maxW="1700px"
          m="auto"
          w="100%"
        >
          <ButtonGroup>
            <Button
              onClick={() => handleSizeChange('100%', 'desktop')}
              as="span"
              role="button"
              bg={activeButton === 'desktop' ? 'white.base' : 'gray.highlight'}
              borderRadius="none"
              borderTopLeftRadius="xl"
              borderTopRightRadius="xl"
              height="unset"
              px={8}
            >
              Desktop
            </Button>
            <Button
              onClick={() => handleSizeChange('390px', 'mobile')}
              as="span"
              role="button"
              bg={activeButton === 'mobile' ? 'white.base' : 'gray.highlight'}
              borderRadius="none"
              borderTopLeftRadius="xl"
              borderTopRightRadius="xl"
              height="unset"
              px={8}
            >
              Mobile
            </Button>
          </ButtonGroup>

          <Box w="100%" bg="white.base" p={6} h="100%">
            <Box
              w={iframeSize}
              h={activeButton === 'mobile' ? '844px' : '100%'}
              margin="auto"
              borderWidth="1px"
              borderColor={
                activeButton === 'mobile' ? 'gray.light' : 'white.base'
              }
              overflow="hidden"
              bg="white.base"
            >
              {formType === FormType.Route ? (
                <iframe
                  src={`${VIEWER_URL}/previewroute/${applicationId}/${versionId}`}
                  width="100%"
                  height="100%"
                  title="Responsive view"
                  scrolling="no"
                />
              ) : (
                <iframe
                  src={`${VIEWER_URL}/preview/${versionId}?user=${encodeURIComponent(JSON.stringify(EntrataSession.user))}`}
                  width="100%"
                  height="100%"
                  title="Responsive view"
                  style={{ display: 'block' }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default PreviewResponsiveWrapper;
