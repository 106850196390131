import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { RequestButton } from 'components/RequestButton';

interface Props {
  message: React.ReactElement | string;
  onClose: () => void;
  onSubmit: (data?: any) => void;
  showCloseBtn?: boolean;
  actionColor?: string;
  actionLabel?: string;
  apiStatus?: boolean | null;
  isLoading?: boolean;
  isOpen: boolean;
  title?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export function ConfirmationModal(props: Props) {
  const {
    isOpen,
    message,
    onClose,
    onSubmit,
    showCloseBtn,
    title,
    actionColor = 'green.dark',
    actionLabel = 'Save',
    isLoading = false,
    apiStatus = null,
    size = 'md',
  } = props;

  return (
    <Modal
      isCentered
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size={size}
    >
      <ModalOverlay backdropFilter="blur(4px) hue-rotate(0deg)" />
      <ModalContent
        bg="white.base"
        w={400}
        borderRadius="2xl"
        border="1px"
        borderColor="gray.light"
      >
        {title && <ModalHeader textAlign="center">{title}</ModalHeader>}
        {showCloseBtn && <ModalCloseButton />}
        <ModalBody px={6} py={10}>
          <Text textAlign="center" fontSize="sm" wordBreak="break-word">
            {message}
          </Text>
        </ModalBody>

        <ModalFooter bg="gray.100" borderBottomRadius="2xl">
          <Button size="md" variant="ghost" onClick={onClose} mr={2}>
            Cancel
          </Button>
          <RequestButton
            mt={0}
            size="md"
            color="white.base"
            bg={actionColor}
            onClick={onSubmit}
            actionBtnText={actionLabel}
            isLoading={isLoading}
            apiStatus={apiStatus}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
