import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { AnyField } from 'types/fields';
import type { AnyPage } from 'types/page';

type BuilderStoreReducer = {
  builderSteps: AnyPage[];
  activeStep: number;
  // eslint-disable-next-line
  activeField: AnyField | {};
  currentTool: string;
  formId: string;
  isReadOnly: boolean;
};

const initialState: BuilderStoreReducer = {
  builderSteps: [],
  activeStep: 0,
  activeField: {},
  currentTool: '',
  formId: '',
  isReadOnly: true,
};

export const builderStoreSlice = createSlice({
  name: 'builderStoreSlice',
  initialState,
  reducers: {
    updateBuilderStore: (
      state,
      action: PayloadAction<Partial<BuilderStoreReducer>>,
    ) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateBuilderStore } = builderStoreSlice.actions;

export default builderStoreSlice.reducer;
