import { createStandaloneToast } from '@chakra-ui/react';
import { NotificationStatus } from 'enums';

function notification(
  title: string,
  message: string,
  type: NotificationStatus,
) {
  const { toast } = createStandaloneToast();
  toast({
    title,
    description: message,
    status: type,
    duration: 3000,
    containerStyle: {
      color: 'white',
      // Temp until Iframe is fixed in prod.
      // Once fixed remove position: 'relative' and  bottom: '60px',
      position: 'relative',
      bottom: process.env.NODE_ENV === 'production' ? '0' : '60px',
    },
  });
}

export default notification;
