import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  heading: string;
  children: React.ReactNode;
  showCloseBtn?: boolean;
  actionBtnText?: string;
  showActionBtn?: boolean;
  closeBtnText?: string;
  showCloseIcon?: boolean;
  size?:
    | '3xl'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | 'xs'
    | '4xl'
    | '5xl'
    | '6xl'
    | 'full';
  closeOnOverlayClick?: boolean;
  onAction?: () => void;
}
const CommonModal = (props: Props) => {
  const {
    isOpen,
    onClose,
    heading,
    showActionBtn = true,
    showCloseBtn = true,
    actionBtnText = 'Submit',
    showCloseIcon = true,
    size = 'md',
    closeBtnText = 'Cancel',
    closeOnOverlayClick = true,
    children,
    onAction,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay backdropFilter="blur(4px) hue-rotate(0deg)" />
      <ModalContent borderRadius="2xl">
        <ModalHeader>{heading}</ModalHeader>
        {showCloseIcon && <ModalCloseButton data-testid="close-modal-btn" />}
        <ModalBody>{children}</ModalBody>
        <ModalFooter borderBottomRadius="2xl" bg="gray.100">
          {showCloseBtn && (
            <Button
              size="sm"
              bg="transparent"
              _hover={{
                bg: 'transparent',
              }}
              mr={3}
              onClick={onClose}
            >
              {closeBtnText}
            </Button>
          )}
          {showActionBtn && (
            <Button
              size="sm"
              variant="action"
              onClick={() => {
                onAction && onAction();
              }}
            >
              {actionBtnText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CommonModal;
