import { combineReducers, configureStore } from '@reduxjs/toolkit';

import applicationReducer from './slices/applicationSlice';
import authReducer from './slices/authSlice';
import builderStoreReducer from './slices/builderStoreSlice';
import unsavedFieldReducer from './slices/unsavedFieldSlice';
import { RootState } from './store';

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
      reducer: rootReducer,
      preloadedState,
    });
  };

  const rootReducer = combineReducers({
      auth: authReducer,
      application: applicationReducer,
      builderStore: builderStoreReducer,
      unsavedField: unsavedFieldReducer,
  });

