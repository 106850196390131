import * as React from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { sizeFormFields } from '@common/ui';

interface FieldProps {
  children: React.ReactElement;
  name: string;
  label: string;
  description?: string;
  required?: boolean;
  labelProps?: any;
  placeholder?: string;
  fieldType?: string;
  fieldState?: any;
  errorMessage?: string;
  showValidationErrorMessage?: boolean;
}

export function FieldWrapper({
  name,
  label,
  description,
  required,
  children,
  fieldState = {},
  labelProps,
  fieldType,
}: FieldProps) {
  return (
    <GridItem my={1} colSpan={sizeFormFields(fieldType || '')}>
      <FormControl id={`${name}`} isDisabled isInvalid={fieldState.invalid}>
        {description && <FormLabel mb={2}>{description}</FormLabel>}
        <Flex justifyContent="space-between">
          <FormLabel {...labelProps}>{label}</FormLabel>
          {!required && <Text variant="OptionalField">Optional</Text>}
        </Flex>
        {children}
        {fieldState.invalid && (
          <FormErrorMessage
            color="red.base"
            fontSize="0.688rem"
            pos="absolute"
            bottom="-16px"
          >
            {fieldState?.error?.message || 'This field is required'}
          </FormErrorMessage>
        )}
      </FormControl>
    </GridItem>
  );
}
