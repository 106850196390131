import { PageType } from '@common/entities';

import type { AnyPage } from 'types/page';

import { destroy, get, patch, post } from '../config';

const PAGE_END_POINT = '/pages';

interface AddPage {
  sequence: number;
  name: string;
  description: string;
  formId: string;
  type: string;
  configuration: object;
}
export const addPage = async (data: AddPage) => {
  const response = await post<AnyPage>(PAGE_END_POINT, data);
  return response;
};

export const deletePage = async (id: string) => {
  if (!id) throw new Error('Page Id is missing.');

  const response = await destroy<AnyPage>(`${PAGE_END_POINT}/${id}`);
  return response;
};

export const getPages = async (formId: string) => {
  if (!formId) throw new Error('Missing parameter.');

  const response = await get<AnyPage[]>(PAGE_END_POINT, {
    params: {
      formid: formId,
    },
  });
  return response;
};

interface PatchPage {
  name?: string;
  description?: string;
  sequence?: number;
  type?: PageType;
  id: string;
  configuration?: object;
}
export const patchPage = async (data: PatchPage) => {
  const { id, ...rest } = data;
  const response = await patch<AnyPage>(`${PAGE_END_POINT}/${id}`, { ...rest });
  return response;
};
