import { SubsidyProgram } from '@common/entities';

import type { Application, ApplicationChangeHistory } from 'types/application';

import { get, patch, post } from '../config';

export const APPLICATION_END_POINTS = '/applications';

export const getApplications = async (clientId: number) => {
  if (!clientId) throw new Error('Please provide client Id.');

  const response = await get<Application[]>(
    `${APPLICATION_END_POINTS}?clientid=${clientId}`,
  );
  return response;
};

type ApplicationResponse = Omit<
  Application,
  'properties' | 'property_associations'
>;
export const getApplication = async (
  applicationId: string,
  formVersionId: string,
) => {
  if (!applicationId || !formVersionId) throw new Error('Missing parameter.');

  const response = await get<ApplicationResponse>(
    `${APPLICATION_END_POINTS}/${applicationId}/versions/${formVersionId}`,
  );
  return response;
};

interface CreateApplication {
  name: string;
  description: string;
  occupancyType: string;
  defaultLanguage: string;
  properties?: {
    propertyId: number;
    propertyName: string;
  }[];
  subsidyPrograms?: SubsidyProgram[];
  templateFormId: string;
}
type CreateApplicationResponse = Omit<
  Application,
  'properties' | 'property_associations'
>;
export const createApplication = async (data: CreateApplication) => {
  const response = await post<CreateApplicationResponse>(
    APPLICATION_END_POINTS,
    data,
  );
  return response;
};

export const getApplicationChanges = async (
  applicationId: string,
  formVersionId: string,
  pageNumber: number,
  pageSize: number,
) => {
  if (!applicationId || !formVersionId || !pageNumber || !pageSize) {
    throw new Error('Missing Parameters.');
  }

  const response = await get<ApplicationChangeHistory[]>(
    `${APPLICATION_END_POINTS}/${applicationId}/versions/${formVersionId}/formchanges/pages/${pageNumber}/${pageSize}`,
  );
  return response;
};

type PatchApplicationResponse = Omit<
  Application,
  'property_associations' | 'updatedBy'
>;
export const patchApplication = async (data: any) => {
  const response = await patch<PatchApplicationResponse>(
    `${APPLICATION_END_POINTS}/${data.id}`,
    data,
  );
  return response;
};

type DeleteApplicationResponse = Omit<
  Application,
  'properties' | 'property_associations' | 'updatedBy' | 'versions'
>;
export const deleteApplication = async (applicationId: string) => {
  if (!applicationId) throw new Error('Application Id is missing.');

  const response = await patch<DeleteApplicationResponse>(
    `${APPLICATION_END_POINTS}/soft-delete/${applicationId}`,
  );
  return response;
};

interface PublishVersionResponse {
  id: string;
  applicationId: string;
  formId: string;
  status: string;
  parentVersionId: string;
  versionNumber: number;
  updatedDate: string;
  updatedBy: number;
  publishedDate: string;
}
export const publishVersion = async (data: {
  applicationId: string;
  formVersionId: string;
}) => {
  const { applicationId, formVersionId } = data;
  if (!applicationId || !formVersionId) throw new Error('Missing parameter.');

  const response = await patch<PublishVersionResponse>(
    `${APPLICATION_END_POINTS}/${applicationId}/versions/${formVersionId}/publish`,
    null,
  );
  return response;
};

type DuplicateApplicationReq = {
  applicationId: string;
  name: string;
};
export const duplicateApplication = async (data: DuplicateApplicationReq) => {
  const { applicationId, name } = data;
  if (!applicationId || !name) throw new Error('Missing parameter.');

  const response = await post(
    `${APPLICATION_END_POINTS}/duplicate-application`,
    data,
  );
  return response?.data;
};
