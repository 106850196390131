let formBuilderUrl: string
let formViewerUrl: string

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  formBuilderUrl = process.env.FORMBUILDER_API_URL
  formViewerUrl = process.env.FORMVIEWER_URL
} else {
  formBuilderUrl = (window as any).env?.FORMBUILDER_API_URL
  formViewerUrl = (window as any).env?.FORMVIEWER_URL
}

const BASE_URL = formBuilderUrl
const VIEWER_URL = formViewerUrl

export { BASE_URL, VIEWER_URL }
