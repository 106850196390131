import { createStandaloneToast } from '@chakra-ui/react';
import { NotificationStatus } from 'enums';

function notification(
  title: string,
  message: string,
  type: NotificationStatus,
) {
  const { toast } = createStandaloneToast();
  toast({
    title,
    description: message,
    status: type,
    duration: 3000,
    containerStyle: { color: 'white' },
  });
}

export default notification;
