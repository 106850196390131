import { get } from '../config';

const PROPERTY_END_POINTS = '/properties';

export const getProperties = async (occupancyType = '') => {
  const params = {
    occupancyType,
  };
  const response = await get<{ propertyId: number; propertyName: string }[]>(
    PROPERTY_END_POINTS,
    { params },
  );
  return response;
};

export const getSubsidyPrograms = async (clientId: number) => {
  const params = {
    clientId,
  };
  const response = await get(
    `${PROPERTY_END_POINTS}/${clientId}/subsidy-programs`,
    { params },
  );
  return response;
};
