/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type ApplicationReducer = {
  selectedFormTemplatedId: string;
  selectedApplicationId: string;
  selectedApplication: any | null;
  selectedRouteId: string | null;
};

const initialState = {
  selectedFormTemplatedId: null,
  selectedApplicationId: null,
  selectedApplication: null,
  selectedRouteId: null,
} as ApplicationReducer;

export const applicationSlice = createSlice({
  name: 'applicationSlice',
  initialState,
  reducers: {
    setSelectedFormTemplateId: (state, action: PayloadAction<string>) => {
      state.selectedFormTemplatedId = action.payload;
    },
    setSelectedApplicationId: (state, action: PayloadAction<string>) => {
      state.selectedApplicationId = action.payload;
    },
    setSelectedApplication: (state, action: PayloadAction<any>) => {
      state.selectedApplication = action.payload;
    },
    setSelectedRouteId: (state, action: PayloadAction<string | null>) => {
      state.selectedRouteId = action.payload;
    },
  },
});

export const {
  setSelectedApplicationId,
  setSelectedFormTemplateId,
  setSelectedApplication,
  setSelectedRouteId,
} = applicationSlice.actions;

export default applicationSlice.reducer;
