import { FeatureFlag } from '@common/entities';
import { get } from 'config';

export const FEATURE_FLAGS_END_POINT = '/admin/feature-enabled';

export const smartAppRoutingEnabled = async (clientId: number) => {
  if (!clientId) throw new Error('Client ID is missing.');

  const response = await get(
    `${FEATURE_FLAGS_END_POINT}/${FeatureFlag.SmartAppRoutingEnabled}/${clientId}`,
  );
  return response.data;
};
