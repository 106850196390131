import { FieldOption, FieldType } from '@common/entities';

import type { Field } from 'types/fields';

import { destroy, patch, post } from '../config';

import { SECTION_END_POINT } from './sections';

const FIELD_END_POINT = '/fields';

interface AddSingleField {
  customType: string;
  customField: boolean;
  label: string;
  description?: string;
  name: string;
  required: boolean;
  sequence: number;
  type: string;
  sectionId: string;
  options?: FieldOption[];
}

export const addSingleField = async (data: AddSingleField) => {
  const { sectionId, ...rest } = data;
  const response = await post<Field>(
    `${SECTION_END_POINT}/${sectionId}/fields`,
    rest,
  );
  return response;
};

interface PatchField {
  label?: string;
  type?: FieldType;
  customType?: FieldType;
  required?: boolean;
  sequence?: number;
  id: string;
  options?: FieldOption[];
}
export const patchField = async (data: PatchField) => {
  const { id, ...rest } = data;
  const response = await patch<Field>(`${FIELD_END_POINT}/${id}`, { ...rest });
  return response;
};

export const deleteField = async (id: string) => {
  if (!id) throw new Error('Field Id is missing.');

  const response = await destroy<Field>(`${FIELD_END_POINT}/${id}`);
  return response;
};
