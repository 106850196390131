export const getAvailableSteps = (
  elements: {
    id: string;
    name: string;
    label: string;
    sequence: number;
    type: string;
    options: any[];
  }[],
  applicationsData: any,
  currentQuestionId: string,
) => {
  if (!currentQuestionId) return [];

  const currentQuestion = elements.find(({ id }) => id === currentQuestionId);
  if (!currentQuestion) {
    // eslint-disable-next-line no-console
    console.warn(`Current question not found: ${currentQuestionId}`);
    return [];
  }

  const filteredElements = elements.filter(
    (el) => !(el.name === 'application_route_opening_message'),
  );

  const messageElements = filteredElements.filter(
    ({ type }) => type === 'static-text',
  );
  const questionElements = filteredElements.filter(
    ({ type }) => type !== 'static-text',
  );

  const messagesGroup = {
    label: 'Messages',
    children: messageElements.map((msg, index) => ({
      label: `Message ${index + 1}`,
      value: msg.id,
      type: 'static-text',
    })),
  };

  const qualifyingQuestionsGroup = {
    label: 'Qualifying Questions',
    children: questionElements
      .filter(({ sequence }) => sequence > currentQuestion.sequence)
      .map((q) => ({
        label: q.label,
        value: q.id,
        type: q.type,
      })),
  };

  const applicationsGroup = {
    label: 'Applications',
    children:
      applicationsData?.data?.map((app: any) => ({
        label: app.name || `Application ${app.id}`,
        value: app.id,
        type: 'application',
      })) || [],
  };

  return [messagesGroup, qualifyingQuestionsGroup, applicationsGroup];
};
