"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nilUuid = void 0;
exports.newUuid = newUuid;
var uuid_1 = require("uuid");
function newUuid() {
    return (0, uuid_1.v1)();
}
var nilUuid = function () {
    return uuid_1.NIL;
};
exports.nilUuid = nilUuid;
