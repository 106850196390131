import type { Application } from 'types/application';

import { post } from '../config';

import { APPLICATION_END_POINTS } from './application';

interface CreateDraft {
  applicationId: string;
  formVersionId: string;
}
type DraftApplicationResponse = Omit<
  Application,
  'properties' | 'property_associations'
>;
export const createDraft = async (data: CreateDraft) => {
  const { applicationId, formVersionId } = data;
  if (!applicationId || !formVersionId) throw new Error('Missing parameters.');

  const response = await post<DraftApplicationResponse>(
    `${APPLICATION_END_POINTS}/${applicationId}/versions/${formVersionId}/draft`,
    null,
  );
  return response;
};
