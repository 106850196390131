import { BlockType } from '@common/entities';

import type { Block } from 'types/fields';

import { destroy, patch, post } from '../config';

import { SECTION_END_POINT } from './sections';

const BLOCK_END_POINT = '/blocks';

interface AddBlock {
  description: string;
  name: string;
  customType: BlockType;
  configuration: any;
  sequence: number;
  sectionId: string;
}
export const addBlock = async (data: AddBlock) => {
  const { sectionId, ...rest } = data;

  const response = await post<Block>(
    `${SECTION_END_POINT}/${sectionId}/blocks`,
    rest,
  );
  return response;
};

interface PatchBlock {
  description?: string;
  required?: boolean;
  sequence?: number;
  id: string;
  configuration?: any;
}
export const patchBlock = async (data: PatchBlock) => {
  const { id, ...requestData } = data;

  const response = await patch<Block>(`${BLOCK_END_POINT}/${id}`, requestData);
  return response?.data;
};

export const deleteBlock = async (id: string) => {
  if (!id) throw new Error('Block Id is missing.');

  const response = await destroy<Block>(`${BLOCK_END_POINT}/${id}`);
  return response;
};
