import type { Section } from 'types/page';

import { destroy, patch, post } from '../config';

export const SECTION_END_POINT = '/sections';

interface CreateSectionRequest {
  title: string;
  sequence: number;
  pageId: string;
}

export const addSection = async (data: CreateSectionRequest) => {
  const response = await post<Section>(SECTION_END_POINT, { ...data });
  return response;
};

interface PatchSectionRequest {
  title: string;
  sequence: number;
  id: string;
}

export const patchSection = async (data: PatchSectionRequest) => {
  const { id, ...rest } = data;

  const response = await patch<Section>(`${SECTION_END_POINT}/${id}`, {
    ...rest,
  });
  return response;
};

export const deleteSection = async (id: string) => {
  if (!id) throw new Error('Section Id is missing.');

  const response = await destroy<Section>(`${SECTION_END_POINT}/${id}`);
  return response;
};
