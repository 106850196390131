import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { NotificationStatus } from 'enums';
import { setSelectedApplicationId } from 'state/slices/applicationSlice';

import { StopSignIcon } from 'components/custom-icons';
import notification from 'components/notification';
import { useApplications } from 'hooks/queries/useApplications';
import { duplicateApplication } from 'services';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  applicationName: string;
  applicationId: string;
}
const DuplicateApplicationModal = (props: Props) => {
  const [isValidInput, setIsValidInput] = useState(true);
  const [inputError, setInputError] = useState<string | null>(null);

  const [apiError, setAPIError] = useState<string | null>(null);

  const { applicationId, applicationName, isOpen, onClose } = props;

  const inputRef = useRef<HTMLInputElement>();

  const { refetch } = useApplications();

  const dispatch = useDispatch();

  const handleClose = () => {
    // Reset state
    setIsValidInput(true);
    setInputError(null);
    setAPIError(null);

    onClose();
  };

  const handleDuplicate = async () => {
    const { value } = inputRef.current;
    setAPIError(null);
    try {
      if (!isValidInput) {
        notification('Error', inputError, NotificationStatus.ERROR);
        return;
      }
      if (!value) {
        notification(
          'Error',
          'Please enter application name.',
          NotificationStatus.ERROR,
        );
        return;
      }
      const response = await duplicateApplication({
        applicationId,
        name: value,
      });
      handleClose();
      await refetch();
      dispatch(setSelectedApplicationId(response?.id));
      notification(
        '',
        `${value} application created successfully.`,
        NotificationStatus.SUCCESS,
      );
    } catch (err) {
      setAPIError(err.message);
    }
  };

  const isValidInputValue = () => {
    const { value } = inputRef.current;
    if (!value) {
      setIsValidInput(false);
      setInputError('Please enter application name.');
      return;
    }

    setIsValidInput(true);
  };

  return (
    <Modal
      id={`copy-application-${applicationId}`}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
    >
      <ModalOverlay backdropFilter="blur(4px) hue-rotate(0deg)" />
      <ModalContent background="white.base" borderRadius="2xl">
        <ModalBody py={10}>
          {apiError && (
            <Alert status="error" bg="red.100" borderRadius={8} mb={5}>
              <StopSignIcon mr={2} />
              <AlertTitle
                fontSize={16}
                fontWeight="normal"
                color="red.800"
                textAlign="center"
              >
                {apiError}
              </AlertTitle>
            </Alert>
          )}

          <FormControl isInvalid={!isValidInput}>
            <Grid templateColumns="110px 1fr" alignItems="center">
              <GridItem>
                <FormLabel flex={1}>Application Name</FormLabel>
              </GridItem>
              <GridItem>
                <Input
                  h="40px"
                  data-testid="duplicate-application-input"
                  defaultValue={`Copy of ${applicationName}`}
                  flex={2}
                  ref={inputRef}
                  onBlur={isValidInputValue}
                />
              </GridItem>
            </Grid>
            <Grid templateColumns="110px 1fr" alignItems="center">
              <GridItem />
              <GridItem>
                <FormErrorMessage fontSize={12}>{inputError}</FormErrorMessage>
              </GridItem>
            </Grid>
          </FormControl>
        </ModalBody>
        <ModalFooter bg="gray.100" borderBottomRadius="2xl">
          <Button
            mr={2}
            onClick={handleClose}
            color="blue.800"
            bg="transparent"
            _hover={{
              backGround: 'transparent',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            size="lg"
            bg="blue.500"
            color="white"
            _hover={{
              backGround: 'blue.700',
            }}
            onClick={handleDuplicate}
          >
            Duplicate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DuplicateApplicationModal;
