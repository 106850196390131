export const enum ColorOptions {
  Dusty = "dusty",
  Bright = "bright",
  Darkest = "darkest",
  Dark = "dark",
  Base = "base",
  Medium = "medium",
  Light = "light",
  Highlight = "highlight",
  Highlighthalf = "highlighthalf",
  Essence = "essence",
}

export interface DefaultColors {
  [key: string]: {
    [key in ColorOptions]+?: string;
  };
}

const defaultColors: DefaultColors = {
  blue: {
    bright: "#0075FE",
    dusty: "#015CC9",
    darkest: "#254F6A",
    dark: "#306688",
    base: "#3878A0",
    medium: "#599BC5",
    light: "#C6DCE8",
    highlight: "#E2EEF3",
  },
  red: {
    bright: "#EC0006",
    darkest: "#981F23",
    dark: "#D7393F",
    base: "#FF6E73",
    medium: "#FEA1A5",
    light: "#EEAAAD",
    highlight: "#F8DDDE",
  },
  yellow: {
    darkest: "#DC8800",
    dark: "#EFA122",
    base: "#FFC464",
    medium: "#FED99B",
    light: "#FEEBCC",
    highlight: "#FDF4E5",
  },
  green: {
    darkest: "#048659",
    dark: "#37A17C",
    base: "#507C56",
    medium: "#75A97D",
    light: "#D2E9E1",
    highlight: "#E8F3EF",
  },
  orange: {
    darkest: "#CE5200",
    dark: "#E97122",
    base: "#F89859",
    medium: "#FABC94",
    light: "#FBDDC9",
    highlight: "#FCEDE3",
  },
  violet: {
    darkest: "#5A0C88",
    dark: "#70428B",
    base: "#A67FBD",
    medium: "#C5ACD4",
    light: "#E1D5E9",
    highlight: "#EFE9F3",
  },
  brown: {
    darkest: "#685044",
    dark: "#867164",
    base: "#A49284",
    medium: "#C1B2A5",
    light: "#D6CFCA",
    highlight: "#F3F1F0",
  },
  gray: {
    darkest: "#4E4D4D",
    dark: "#777676",
    dusty: "#999",
    base: "#ABAAAA",
    medium: "#D4D3D3",
    light: "#E0E0E0",
    highlight: "#E8E8E8",
    highlighthalf: "#F5F5F5",
    essence: "#F9F9F9",
  },
  silver: {
    darkest: "#767676",
    dark: "#707070",
    dusty: "#b3b3b3",
    base: "#CCC",
    medium: "#D9D9D9",
    light: "#E5E5E5",
    highlighthalf: "#EEE",
    highlight: "#f2f2f2",
    essence: "#f7f7f7",
  },
  black: {
    darkest: "#222",
    base: "#302F2F",
  },
  white: {
    dark: "#FFF",
    base: "#FDFDFD",
  },
};

export default defaultColors;
