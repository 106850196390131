import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ChakraProvider, createStandaloneToast } from '@chakra-ui/react';
import { theme } from '@common/ui';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from 'App';
import { PersistGate } from 'redux-persist/integration/react';
import { queryClient } from 'test-utils/testUtils';

import '@entrata/i18n';

import { persistor, store } from './state/store';

// sync translation data from global var
// @ts-ignore
i18nUtils.syncTranslations('__APPLICATIONS_BUILDER__');

if (process.env.NODE_ENV === 'test') {
  import('./mocks/browser').then(({ worker }) => worker.start());
}

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(rootElement);
const { ToastContainer } = createStandaloneToast();

root.render(
  <StrictMode>
    <ChakraProvider theme={theme} cssVarsRoot="body">
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
        <ToastContainer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ChakraProvider>
  </StrictMode>,
);
