import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import applicationReducer from './slices/applicationSlice';
import authReducer from './slices/authSlice';
import builderStoreReducer from './slices/builderStoreSlice';
import unsavedFieldReducer from './slices/unsavedFieldSlice';

export const rootReducer = combineReducers({
  auth: authReducer,
  application: applicationReducer,
  builderStore: builderStoreReducer,
  unsavedField: unsavedFieldReducer,
});

export const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['application'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
  devTools: process.env.NODE_ENV === 'development',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
