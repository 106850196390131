import { DisplayAction, ValueOperator } from '@common/entities';
import { destroy, patch, post } from 'config';

import { DisplayLogic } from 'types/display-logic';

const CONDITION_LOGIC_END_POINTS = '/display-logic';

interface SourceFieldValues {
  value: string;
  optionId?: string;
}
interface SaveConditionLogicReq {
  targetFieldId?: string;
  targetBlockId?: string;
  displayAction: DisplayAction;
  sourceBlockFieldId?: string;
  sourceFieldId?: string;
  sourceOperator: ValueOperator;
  sourceFieldValues: SourceFieldValues[];
}
export const saveConditionLogic = async (data: SaveConditionLogicReq) => {
  const response = await post<DisplayLogic>(CONDITION_LOGIC_END_POINTS, data);
  return response?.data;
};

export const patchConditionLogic = async (data: SaveConditionLogicReq) => {
  const response = await patch<DisplayLogic>(CONDITION_LOGIC_END_POINTS, data);
  return response?.data;
};

interface DeleteConditionLogic {
  path: 'targetField' | 'targetBlock';
  targetId: string;
}
export const deleteConditionLogic = async ({
  path,
  targetId,
}: DeleteConditionLogic) => {
  const response = await destroy<{ id: number; name: string }[]>(
    `${CONDITION_LOGIC_END_POINTS}/${path}/${targetId}`,
  );
  return response?.data;
};
