import type { ApplicationTemplate } from 'types/application-template';

import { get } from '../config';

const TEMPLATE_END_POINTS = '/application-templates';

export const getApplicationTemplates = async () => {
  const response = await get<ApplicationTemplate[]>(TEMPLATE_END_POINTS);
  return response;
};
