import { get, patch, post } from '../config';

export const APPLICATION_ROUTES_END_POINTS = '/application-routes';

interface CreateSmartRoute {
  name: string;
  description: string;
  properties?: {
    propertyId: number;
    propertyName: string;
  }[];
}

export const getSmartRoutes = async (clientId: number) => {
  if (!clientId) throw new Error('Client ID is missing.');

  const response = await get(
    `${APPLICATION_ROUTES_END_POINTS}?clientid=${clientId}`,
  );
  return response.data;
};

export const getSmartRoute = async (routeId: string, versionId: string) => {
  if (!routeId || !versionId) throw new Error('Missing parameter.');

  const response = await get(
    `${APPLICATION_ROUTES_END_POINTS}/${routeId}/versions/${versionId}`,
  );
  return response;
};

export const createSmartRoute = async (data: CreateSmartRoute) => {
  const response = await post(APPLICATION_ROUTES_END_POINTS, data);
  return response;
};

export const patchSmartRoute = async (data: any) => {
  const response = await patch(
    `${APPLICATION_ROUTES_END_POINTS}/${data.id}`,
    data,
  );
  return response;
};

export const deleteSmartRoute = async (routeId: string) => {
  if (!routeId) throw new Error('Route Id is missing.');

  const response = await patch(
    `${APPLICATION_ROUTES_END_POINTS}/${routeId}/soft-delete`,
  );
  return response;
};

export const createRouteDraft = async (data: {
  routeId: string;
  versionId: string;
}) => {
  const { routeId, versionId } = data;
  if (!routeId || !versionId) throw new Error('Missing parameter.');

  const response = await post(
    `${APPLICATION_ROUTES_END_POINTS}/${routeId}/versions/${versionId}/draft`,
    null,
  );
  return response;
};

export const publishRouteVersion = async (data: {
  routeId: string;
  versionId: string;
}) => {
  const { routeId, versionId } = data;
  if (!routeId || !versionId) throw new Error('Missing parameter.');

  const response = await patch(
    `${APPLICATION_ROUTES_END_POINTS}/${routeId}/versions/${versionId}/publish`,
    null,
  );
  return response;
};

export const duplicateRoute = async (data: {
  routeId: string;
  name: string;
}) => {
  const { routeId, name } = data;
  if (!routeId || !name) throw new Error('Missing parameter.');

  const response = await post(
    `${APPLICATION_ROUTES_END_POINTS}/duplicate-route`,
    { routeId, name },
  );
  return response;
};
