import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { EntrataSession } from 'auth/EntrataSession';
import Login from 'auth/Login';
import axios from 'axios';
import { BASE_URL } from 'config/URL';
import { NotificationStatus } from 'enums';
import queryString from 'query-string';
import { setIsAuthenticated } from 'state/slices/authSlice';
import { RootState } from 'state/store';
import PreviewResponsiveWrapper from 'views/application-preview/PreviewResponsiveWrapper';

import Loader from 'components/Loader';
import notification from 'components/notification';
import PageNotFound from 'components/PageNotFound';
import type { ValidateSessionResponse } from 'types/auth';

const DashBoard = lazy(() => import('views/dashboard/DashBoard'));
const ApplicationTemplate = lazy(() => import('views/ApplicationTemplate'));
const ApplicationForm = lazy(
  () => import('views/application-form/ApplicationForm'),
);
const FormBuilder = lazy(() => import('views/form-builder/FormBuilder'));

function App() {
  const [useMockLogin, setUseMockLogin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authVerificationCompleted, setAuthVerificationCompleted] =
    useState(false);

  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const verify = async (token: string) => {
    try {
      if (!token) throw new Error('Token is missing.');

      const response = await axios({
        method: 'post',
        url: `${BASE_URL}/auth/verifyToken`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 201) throw new Error('Failed to verify token');

      dispatch(setIsAuthenticated(true));
    } catch (err) {
      EntrataSession.setToken('');
      notification('Error', err.message, NotificationStatus.ERROR);
    } finally {
      setIsLoading(false);
      setAuthVerificationCompleted(true);
    }
  };

  const validateSession = async (session: { sid: string }) => {
    try {
      if (!session) throw new Error('Missing session value.');

      const response = await axios<ValidateSessionResponse>({
        method: 'post',
        url: `${BASE_URL}/auth/validate-session`,
        data: session,
        withCredentials: true,
      });

      if (response.status !== 201)
        throw new Error('Failed to validate session.');

      EntrataSession.setUser(response.data.user);
      EntrataSession.setEntrataUrl(response.data.entrataRedirectLink);
      dispatch(setIsAuthenticated(true));
    } catch (err) {
      notification('Error', err.message, NotificationStatus.ERROR);
    } finally {
      setIsLoading(false);
      setAuthVerificationCompleted(true);
    }
  };

  useEffect(() => {
    async function doSignIn() {
      if (!isAuthenticated && EntrataSession.builderSession) {
        setIsLoading(true);
        validateSession(EntrataSession.builderSession);
      } else if (EntrataSession.token) {
        setIsLoading(true);
        verify(EntrataSession.token);
      } else {
        setAuthVerificationCompleted(true);
        setIsLoading(false);
      }
    }
    if (!useMockLogin) {
      // eslint-disable-next-line no-restricted-globals
      const sessionParameter = location.search;
      if (sessionParameter) {
        EntrataSession.setBuilderSession(queryString.parse(sessionParameter));
      }
    }
    setUseMockLogin(
      process.env.NODE_ENV === 'development' &&
        process.env.USE_MOCK_LOGIN === 'true',
    );
    doSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authVerificationCompleted || isLoading) return <Loader />;

  if (
    isAuthenticated &&
    !useMockLogin &&
    process.env.NODE_ENV === 'development'
  ) {
    window.history.replaceState(null, '', '/');
  }

  const router = !useMockLogin
    ? createBrowserRouter(
        createRoutesFromElements(
          <Route>
            {isAuthenticated ? (
              <Route path="/">
                <Route index element={<DashBoard />} />
                <Route path="application">
                  <Route
                    path="templates"
                    index
                    element={<ApplicationTemplate />}
                  />
                  <Route path="details" element={<ApplicationForm />} />
                  <Route
                    path="form-builder/:applicationId/:versionId"
                    element={<FormBuilder />}
                  />
                </Route>
                <Route
                  path="/application/preview/:applicationId/:versionId"
                  element={<PreviewResponsiveWrapper />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            ) : (
              <Route path="*" element={<PageNotFound />} />
            )}
          </Route>,
        ),
      )
    : createBrowserRouter(
        createRoutesFromElements(
          <Route>
            {isAuthenticated ? ( //  Protected routes
              <Route path="/">
                <Route index element={<DashBoard />} />
                <Route path="application">
                  <Route
                    path="templates"
                    index
                    element={<ApplicationTemplate />}
                  />
                  <Route path="details" element={<ApplicationForm />} />
                  <Route
                    path="form-builder/:applicationId/:versionId"
                    element={<FormBuilder />}
                  />
                </Route>
                <Route
                  path="/application/preview/:applicationId/:versionId"
                  element={<PreviewResponsiveWrapper />}
                />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            ) : (
              <Route path="*" element={<Login setIsLoading={setIsLoading} />} />
            )}
          </Route>,
        ),
      );

  return (
    <Flex direction="column" height="100%" bg="gray.highlighthalf">
      <Box flex="1">
        <Suspense fallback={<Loader />}>
          <RouterProvider router={router} />
        </Suspense>
      </Box>
    </Flex>
  );
}

export default App;
