import React from 'react';
import { Card, Center, Heading } from '@chakra-ui/react';

const PageNotFound = () => {
  return (
    <Center mt="10em">
      <Card p="5em" textAlign="center">
        <Heading as="h1" size="2xl">
          404
        </Heading>
        <Heading mt={3} as="h1" size="2xl">
          Page not found
        </Heading>
      </Card>
    </Center>
  );
};

export default PageNotFound;
