import { useQuery } from '@tanstack/react-query';
import { EntrataSession } from 'auth/EntrataSession';

import { QueryKey } from 'hooks/queryKey';

import { getApplications } from '../../services/index';

export const useApplications = () => {
  return useQuery({
    queryKey: [QueryKey.UseApplications],
    queryFn: () => getApplications(EntrataSession?.user?.clientId),
  });
};
