import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { NotificationStatus } from 'enums';
import { setSelectedRouteId } from 'state/slices/applicationSlice';
import { RootState } from 'state/store';

import { StopSignIcon } from 'components/custom-icons';
import notification from 'components/notification';
import { duplicateRoute } from 'services';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  routeName: string;
  routeId: string;
}
const DuplicateRouteModal = (props: Props) => {
  const [isValidInput, setIsValidInput] = useState(true);
  const [inputError, setInputError] = useState<string | null>(null);
  const { clientId } = useSelector((state: RootState) => state.user);

  const [apiError, setAPIError] = useState<string | null>(null);

  const { routeId, routeName, isOpen, onClose } = props;

  const inputRef = useRef<HTMLInputElement>();

  const dispatch = useDispatch();

  // const { refetch } = useRoutes(clientId);

  const queryClient = useQueryClient();

  const handleClose = () => {
    // Reset state
    setIsValidInput(true);
    setInputError(null);
    setAPIError(null);

    onClose();
  };

  const handleDuplicate = async () => {
    const { value } = inputRef.current;
    setAPIError(null);
    try {
      if (!isValidInput) {
        notification('Error', inputError, NotificationStatus.ERROR);
        return;
      }
      if (!value) {
        notification(
          'Error',
          'Please enter route name.',
          NotificationStatus.ERROR,
        );
        return;
      }
      const response = await duplicateRoute({
        routeId,
        name: value,
      });
      handleClose();
      queryClient.invalidateQueries({ queryKey: ['smartRoutes', clientId] });
      dispatch(setSelectedRouteId(response?.data?.id));
      notification(
        '',
        `${value} route created successfully.`,
        NotificationStatus.SUCCESS,
      );
    } catch (err) {
      setAPIError(err.message);
    }
  };

  const isValidInputValue = () => {
    const { value } = inputRef.current;
    if (!value) {
      setIsValidInput(false);
      setInputError('Please enter the route name.');
      return;
    }

    setIsValidInput(true);
  };

  return (
    <Modal
      id={`copy-route-${routeId}`}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
    >
      <ModalOverlay backdropFilter="blur(4px) hue-rotate(0deg)" />
      <ModalContent background="white.base" borderRadius="2xl">
        <ModalBody py={10}>
          {apiError && (
            <Alert status="error" bg="red.100" borderRadius={8} mb={5}>
              <StopSignIcon mr={2} />
              <AlertTitle
                fontSize={16}
                fontWeight="normal"
                color="red.800"
                textAlign="center"
              >
                {apiError}
              </AlertTitle>
            </Alert>
          )}

          <FormControl isInvalid={!isValidInput}>
            <Grid templateColumns="110px 1fr" alignItems="center">
              <GridItem>
                <FormLabel flex={1}>Route Name</FormLabel>
              </GridItem>
              <GridItem>
                <Input
                  h="40px"
                  data-testid="duplicate-routeId-input"
                  defaultValue={`Copy of ${routeName}`}
                  flex={2}
                  ref={inputRef}
                  onBlur={isValidInputValue}
                />
              </GridItem>
            </Grid>
            <Grid templateColumns="110px 1fr" alignItems="center">
              <GridItem />
              <GridItem>
                <FormErrorMessage fontSize={12}>{inputError}</FormErrorMessage>
              </GridItem>
            </Grid>
          </FormControl>
        </ModalBody>
        <ModalFooter bg="gray.100" borderBottomRadius="2xl">
          <Button
            mr={2}
            onClick={handleClose}
            color="blue.800"
            bg="transparent"
            _hover={{
              backGround: 'transparent',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            size="lg"
            bg="blue.500"
            color="white"
            _hover={{
              backGround: 'blue.700',
            }}
            onClick={handleDuplicate}
          >
            Duplicate
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DuplicateRouteModal;
