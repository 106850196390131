import { get } from 'config';

const AFFORDABLE_END_POINTS = '/affordable';

interface GetAffordableCompanyDocument {
  count: number;
  custom: { id: number; name: string }[];
  system: { id: number; name: string }[];
}
export const getAffordableCompanyDocument = async () => {
  const response = await get<GetAffordableCompanyDocument>(
    `${AFFORDABLE_END_POINTS}/company-documents/1/2`,
  );
  return response?.data;
};
