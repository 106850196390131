import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { MemoryRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { render as rtlRender } from '@testing-library/react';
import { setupStore } from 'state/setupStore';

import defaultColors from 'assets/theme/colors';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const theme = extendTheme({
  colors: {
    ...defaultColors,
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontSize: '11px',
      },
    },
  },
});

function render(component: React.ReactElement, store = setupStore()) {
  return rtlRender(
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <ReduxProvider store={store}>
          <MemoryRouter>{component}</MemoryRouter>
        </ReduxProvider>
      </ChakraProvider>
    </QueryClientProvider>,
  );
}

export * from '@testing-library/react';

export { render };
