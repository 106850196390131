import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AuthReducer = {
  isAuthenticated: boolean;
};

const initialState = {
  isAuthenticated: false,
} as AuthReducer;

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      // eslint-disable-next-line no-param-reassign
      state.isAuthenticated = action.payload;
    },
  },
});

export const { setIsAuthenticated } = authSlice.actions;
export default authSlice.reducer;
