// eslint-disable-next-line camelcase
import { Organization } from '@common/entities';
// eslint-disable-next-line camelcase
import { jwtDecode } from 'jwt-decode';

export interface User {
  id: number;
  email: string;
  name: string;
  clientId: number;
  token?: string;
  org: Organization;
}
export class EntrataSession {
  public static readonly USER = 'entrata.user';

  private static readonly SESSION = '_entrata-session_';

  private static readonly BUILDER_SESSION = 'builder.session';

  private static readonly REAUTHENTICATED = 'entrata.reauthenticated';

  private static readonly ENTRATA_URL = 'entrata.url';

  private static decode(token: string) {
    const tokenData: any = jwtDecode(token);
    const user: User = {
      id: tokenData.sub,
      email: tokenData.email,
      name: tokenData.name,
      clientId: tokenData.clientId,
      org: new Organization(),
      token,
    };
    return user;
  }

  static get user(): User | null {
    const user = sessionStorage.getItem(this.USER);
    if (user) {
      return JSON.parse(user);
    }

    const { token } = this;
    return token !== null ? this.decode(token) : null;
  }

  static get token(): string | null {
    return sessionStorage.getItem(EntrataSession.SESSION);
  }

  static get entrataUrl(): string | null {
    return sessionStorage.getItem(EntrataSession.ENTRATA_URL);
  }

  static setToken(token: string) {
    sessionStorage.setItem(this.SESSION, token);
  }

  static setUser(user: User) {
    sessionStorage.setItem(this.USER, JSON.stringify(user));
  }

  static setEntrataUrl(url: string) {
    sessionStorage.setItem(this.ENTRATA_URL, url);
  }

  static get builderSession(): any | null {
    const session = sessionStorage.getItem(this.BUILDER_SESSION);
    if (session) return JSON.parse(session);

    return null;
  }

  static setBuilderSession(session: any) {
    sessionStorage.setItem(this.BUILDER_SESSION, JSON.stringify(session));
  }

  static get reauthenticated(): boolean {
    const reauthenticated = sessionStorage.getItem(this.REAUTHENTICATED);
    return reauthenticated !== null && reauthenticated !== '';
  }

  static setReauthenticated(value: string) {
    sessionStorage.setItem(this.REAUTHENTICATED, value);
  }
}
